@import "../../colors.scss";

.delegateAddTripButton {
  background: $teal !important;
}

.delegateAddTripButton:hover {
  background: $navy !important;
}

.isVisuallyDisabled {
  color: #a7acaf !important;
  background: $gray1 !important;
  cursor: default;
}
