.dropdownContainer {
  position: relative;
}

.transparentClearButton {
  position: absolute;
  right: 40px;
  bottom: 0;
  width: 32px;
  height: 40px;
  background: transparent;
  border: none;
  cursor: pointer;
}
